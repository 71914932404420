import React, { useRef, useState } from "react"
import * as Yup from "yup"
import { Formik } from "formik"
import { Helmet } from "react-helmet"

import { Table, Std } from "../../style/tables"

import { dataURItoBlob } from "../../utils/form-data"
import { standardRequiredText, dateRegex } from "../../constants"
import {
  submitForm,
  handleSubmitResponse,
  jsonToFormData,
} from "../../services"

import TyneTec from "../../assets/images/logos/tynetec-logo.png"
import {
  LCard,
  FieldSet,
  Col2,
  Col2Constant,
  CardGrey,
  HeaderWithLogo,
} from "../../style/containers"

import Form from "../../components/form/FormHelper"
import Layout from "../../components/layout"
import SButton from "../../components/button"
import Signature from "../../components/Signature"
import { FormikTabs, FormikInput, FormikCheckbox } from "../../components/form"

const TynetecNewStarterForm = () => {
  const sigCanvas = useRef({})
  const [signatureDrawn, setSignatureDrawn] = useState()
  const [errorText, setErrorText] = useState([])

  const onSubmit = async (values, actions) => {
    if (signatureDrawn) {
      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/ false || !!document.documentMode

      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia

      let file

      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png")
      const blobContent = dataURItoBlob(dataURL)
      const formData = jsonToFormData(values)

      if (isEdge || isIE) {
        file = new Blob([blobContent], "signature.png", {
          type: "image/png",
        })
      } else {
        file = new File([blobContent], "signature.png", { type: "image/png" })
      }

      formData.append("signature", file)

      let res = await submitForm(formData, "")
      handleSubmitResponse(res, setErrorText)
    } else {
      setErrorText([
        "You must sign a signature before submitting. If you've already added a signature please clear and sign again",
      ])
    }
    actions.setSubmitting(false)
  }

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tynetec New Starter Form</title>
      </Helmet>
      <LCard>
        <Formik
          initialValues={{
            title: "",
            first_name: "",
            surname: "",
            worked_for_tynetec_before: "",
            tynetec_manager: "",
            tynetec_department: "",
            tynetec_start_date: "",
            tynetec_end_date: "",
            understands_absence_procedure: 0,
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().required(standardRequiredText),
            first_name: Yup.string().required(standardRequiredText),
            surname: Yup.string().required(standardRequiredText),
            worked_for_tynetec_before: Yup.string().required(
              standardRequiredText
            ),
            tynetec_manager: Yup.string(),
            tynetec_department: Yup.string(),
            tynetec_start_date: Yup.string().matches(
              dateRegex,
              "Must be a valid date in the format dd/mm/yyyy"
            ),
            tynetec_end_date: Yup.string().matches(
              dateRegex,
              "Must be a valid date in the format dd/mm/yyyy"
            ),
            understands_absence_procedure: Yup.number().oneOf(
              [1],
              standardRequiredText
            ),
          })}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values, isSubmitting }) => (
            <Form>
              <HeaderWithLogo>
                <h1>Tynetec New Starter Form</h1>
                <img src={TyneTec} alt="Tynetec Logo" />
              </HeaderWithLogo>
              <h2>Confirmation of Temporary Assignment</h2>
              <p>
                <b>
                  This document is to confirm you will be working on a contract
                  of services via Pin Point Recruitment at TYNETEC on a
                  temporary contract:
                </b>
              </p>
              <Table>
                <tbody>
                  <tr>
                    <Std>
                      <b>Position:</b>
                    </Std>
                    <Std>Assembly Operative</Std>
                  </tr>
                  <tr>
                    <Std>
                      <b>Location:</b>
                    </Std>
                    <Std>
                      10 Cowley Road, Riverside Business Park, Blyth, NE24 5TF
                    </Std>
                  </tr>
                  <tr>
                    <Std>
                      <b>Reporting To:</b>
                    </Std>
                    <Std>Assembly Operative</Std>
                  </tr>
                  <tr>
                    <Std>
                      <b>Shift Times:</b>
                    </Std>
                    <Std>Day Shift: 9am – 5pm</Std>
                  </tr>
                  <tr>
                    <Std>
                      <b>Contract Length:</b>
                    </Std>
                    <Std>Ongoing</Std>
                  </tr>
                  <tr>
                    <Std>
                      <b>Pay Rates:</b>
                    </Std>
                    <Std>£8.21/hour</Std>
                  </tr>
                  <tr>
                    <Std>
                      <b>Training:</b>
                    </Std>
                    <Std>
                      Colleagues will take part in a company site tour.
                      Colleagues will be made aware of the Health &amp; Safety
                      policies whilst on site.
                    </Std>
                  </tr>
                  <tr>
                    <Std>
                      <b>Job Spec:</b>
                    </Std>
                    <Std>
                      Duties will include undertaking assembly procedures and
                      electrical prototype wiring. Duties may also include
                      soldering and following engineering drawings. Assembly of
                      electrical circuits or components and wiring, crimping and
                      looming will be a part of your daily tasks.
                    </Std>
                  </tr>
                  <tr>
                    <Std>
                      <b>PPE:</b>
                    </Std>
                    <Std>Safety boots</Std>
                  </tr>
                </tbody>
              </Table>
              <FieldSet>
                <h2>Your details</h2>
                <FormikTabs
                  setFieldValue={setFieldValue}
                  name="title"
                  label="Title"
                  tabs={[
                    { name: "Mr", label: "Mr" },
                    { name: "Mrs", label: "Mrs" },
                    { name: "Miss", label: "Miss" },
                    { name: "Ms", label: "Ms" },
                  ]}
                />
                <Col2>
                  <FormikInput
                    name="first_name"
                    type="text"
                    label="First name"
                  />
                  <FormikInput name="surname" type="text" label="Surname" />
                </Col2>
                <FormikTabs
                  name="worked_for_tynetec_before"
                  label="Have you worked for TYNETEC or any affiliated organisation before?"
                  setFieldValue={setFieldValue}
                  tabs={[
                    { name: "Yes", label: "Yes" },
                    { name: "No", label: "No" },
                  ]}
                />
                {values.worked_for_tyentec_before === "Yes" ? (
                  <Col2>
                    <FormikInput
                      name="tynetec_manager"
                      type="text"
                      label="Manager"
                    />
                    <FormikInput
                      name="tynetec_department"
                      type="text"
                      label="Department"
                    />
                    <Col2Constant>
                      <FormikInput
                        name="tynetec_start_date"
                        type="text"
                        label="Start Date"
                      />
                      <FormikInput
                        name="tynetec_end_date"
                        type="text"
                        label="End Date"
                      />
                    </Col2Constant>
                  </Col2>
                ) : (
                  ""
                )}
              </FieldSet>
              <FieldSet>
                <h2>TYNETEC Absence Procedure</h2>
                <p>
                  It is important that you attend work for each scheduled shift.
                  If you are unable to attend work due to absence or any other
                  reason you must follow the correct absence procedure.
                </p>
                <p>
                  It is your responsibility to contact Pin Point and inform them
                  of any absence or lateness. You must always contact us
                  yourself unless you are physically unable to do so.
                </p>
                <p>
                  <b>
                    You must contact your Pin Point representative at least 1
                    hour prior to the start of your shift on the details below:
                  </b>
                </p>
                <Col2>
                  <CardGrey>
                    <b>Lauren Manson</b>
                    <span>
                      Mobile: <a href="tel: 07889537319">07889 537319</a>
                    </span>
                    <span>
                      Tel: <a href="tel: 01912615205">0191 2615205</a>
                    </span>
                  </CardGrey>
                </Col2>
                <p>
                  Failure to follow the correct absence procedure could result
                  in disciplinary action being taken against you. Should you
                  accrue three or more absences in your probationary period of
                  12 weeks this may result in your dismissal from the contract.
                </p>
                <FormikCheckbox
                  bold={true}
                  label="I have read and understand the TYNETEC Absence Procedure"
                  name="understands_absence_procedure"
                />
              </FieldSet>
              <Signature
                signatureDrawn={signatureDrawn}
                setSignatureDrawn={setSignatureDrawn}
                sigCanvas={sigCanvas}
              />
              {errorText
                ? errorText.map(e => (
                    <Error style={{ marginTop: "1rem" }} key={e}>
                      {e}
                    </Error>
                  ))
                : null}
              <SButton disabled={isSubmitting} type="submit">
                Submit
              </SButton>
            </Form>
          )}
        </Formik>
      </LCard>
    </Layout>
  )
}
export default TynetecNewStarterForm
